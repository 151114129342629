<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <h1>Test</h1>
  </v-container>
</template>

<script>
export default {
  name: 'DashboardView',
  metaInfo: {
    title: 'Dashboard',
  },
};
</script>
